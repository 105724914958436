@font-face {
  font-family: "JUST Sans ExBold";
  src: url("JUSTSans-ExBd.eot");
  src: url("JUSTSans-ExBd.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-ExBd.woff2") format("woff2"),
    url("JUSTSans-ExBd.woff") format("woff"),
    url("JUSTSans-ExBd.ttf") format("truetype"),
    url("JUSTSans-ExBd.svg#JUSTSans-ExBd") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JUST Sans";
  src: url("JUSTSans-Md.eot");
  src: url("JUSTSans-Md.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-Md.woff2") format("woff2"),
    url("JUSTSans-Md.woff") format("woff"),
    url("JUSTSans-Md.ttf") format("truetype"),
    url("JUSTSans-Md.svg#JUSTSans-Md") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JUST Sans";
  src: url("JUSTSans-Bd.eot");
  src: url("JUSTSans-Bd.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-Bd.woff2") format("woff2"),
    url("JUSTSans-Bd.woff") format("woff"),
    url("JUSTSans-Bd.ttf") format("truetype"),
    url("JUSTSans-Bd.svg#JUSTSans-Bd") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JUST Sans";
  src: url("JUSTSans-Rg.eot");
  src: url("JUSTSans-Rg.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-Rg.woff2") format("woff2"),
    url("JUSTSans-Rg.woff") format("woff"),
    url("JUSTSans-Rg.ttf") format("truetype"),
    url("JUSTSans-Rg.svg#JUSTSans-Rg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JUST Sans";
  src: url("JUSTSans-SmBd.eot");
  src: url("JUSTSans-SmBd.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-SmBd.woff2") format("woff2"),
    url("JUSTSans-SmBd.woff") format("woff"),
    url("JUSTSans-SmBd.ttf") format("truetype"),
    url("JUSTSans-SmBd.svg#JUSTSans-SmBd") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JUST Sans";
  src: url("JUSTSans-Lt.eot");
  src: url("JUSTSans-Lt.eot?#iefix") format("embedded-opentype"),
    url("JUSTSans-Lt.woff2") format("woff2"),
    url("JUSTSans-Lt.woff") format("woff"),
    url("JUSTSans-Lt.ttf") format("truetype"),
    url("JUSTSans-Lt.svg#JUSTSans-Lt") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
