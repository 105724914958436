.loader {
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}
  .p-relative{
    position: relative;
  }
  .spinner {
    border: 5px solid #f05a2547;
    border-radius: 50%;
    border-top: 5px solid #f05a25;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  