.pricing-plans {
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 64px;
  }
  
  .pricing-card {
    --col: #e4e4e7;
    position: relative;
    min-width: 360px;
    padding: 32px;
    padding-bottom: 35px;
    border-radius: 4px;
    border: 1px solid #f05a25;
    background-color: #ffffff20;
    box-shadow: 0 0 32px transparent;
    text-align: center;
  }
  
  .pricing-card {
    --col: #f05a25;
  }
  
 
  
  .pricing-card:hover {
    border-color: var(--col);
    background-color: #fffcfc80;
    box-shadow: 0 0 32px #f05a25;
    transform: translateY(-16px) scale(1.02);
    transition: all 0.5s ease;
  }
  
  .pricing-card > *:not(:last-child) {
    margin-bottom: 32px;
  }
  
  .pricing-card .heading h4 {
    padding-bottom: 12px;
    color: var(--col);
    font-size: 24px;
    font-weight: normal;
  }
  
  .pricing-card .heading p {
    color: #a3a3a3;
    font-size: 14px;
    font-weight: lighter;
  }
  
  .pricing-card .price {
    position: relative;
    color: var(--col);
    font-size: 40px;
    font-weight: bold;
  }
  
  .pricing-card .price sub {
    position: absolute;
    bottom: 25px;
    color: #a3a3a3;
    font-size: 14px;
    font-weight: lighter;
  }
  
  .pricing-card .features li {
    padding-bottom: 16px;
    color: #a3a3a3;
    font-size: 16px;
    font-weight: lighter;
    text-align: left;
  }
  
  .pricing-card .features li i,
  .pricing-card .features li strong {
    color: #e4e4e7;
    font-size: 16px;
    text-align: left;
  }
  
  .pricing-card .features li strong {
    padding-left: 24px;
  }
  
  .pricing-card .cta-btn {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--col);
    background-color: var(--col);
    color: #e4e4e7;
    font-size: 20px;
    font-weight: bold;
  }
  
  .pricing-card .cta-btn:active {
    background-color: transparent;
    color: var(--col);
    transition: all 0.3s ease;
  }

  .pricing-card.active {
    border: 2px solid #000000; /* Example style for active card */
    background-color: #ffffff; 
  }
  .pricing-card.active:hover {
    box-shadow: 0 0 32px #000000;
  }