/*****************************
Root Variables
*****************************/
:root {
  /* Primary Colors */
  --bs-primary: #f05a25 !important;
  --bs-hover-primary: #f37b4a !important;
  --bs-secondary: #1f2937;
  --bs-success: #1f2937;
  --bs-dark: #1f2937;
  --background-color: #ffffff;
  --text-color: #1f2937;
  --link-color: #f05a25;
  --link-hover-color: var(--bs-primary);
  --input-color: #0e172a;
  --light-muted: #4b5563;
  --muted: rgba(107, 114, 128, 1);
  --bs-border-color: #f3f4f6 !important;
  /* Typography */
  --font-family-sans:'Roboto', sans-serif;
  --font-size-base: 14px;
  --font-weight-normal: 400 !important;
  --font-weight-bold: 600 !important;
  --line-height-base: 1.5;

  /* Shadows */
  --box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

/*****************************
Typography 
*****************************/
body {
  font-family: var(--font-family-sans) !important;
  font-weight: var(--font-weight-normal) !important;
  font-size: var(--font-size-base) !important;
  color: var(--text-color) !important;
  line-height: var(--line-height-base) !important;
  overflow-x: hidden;
  background: var(--background-color);
  box-sizing: border-box;
}

p {
  color: var(--text-color);
}

a {
  outline: none !important;
  color: var(--link-color);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

a:focus {
  text-decoration: none !important;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: none !important;
}

input {
  outline: none !important;
  color: var(--input-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold) ;
  color: var(--text-color) !important;
  margin-top: 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 36px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

label {
  font-weight: var(--font-weight-normal);
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

small {
  font-size: 80%;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.font10 {
  font-size: 0.625rem !important;
}

.font12 {
  font-size: 0.75rem !important;
}

.font13 {
  font-size: 0.8125rem !important;
}

.font14 {
  font-size: 0.875rem !important;
}

.font15 {
  font-size: 0.9375rem !important;
}

.font16 {
  font-size: 1rem !important;
}

.font18 {
  font-size: 1.125rem !important;
}

.font20 {
  font-size: 1.25rem !important;
}

.font25 {
  font-size: 1.5625rem !important;
}

.font300 {
  font-weight: 300 !important;
}

.font400 {
  font-weight: 400 !important;
}

.font500 {
  font-weight: 500 !important;
}

.font600 {
  font-weight: 600 !important;
}

.font700 {
  font-weight: 700 !important;
}

.font-muted {
  color: var(--muted) !important;
}

.font-light-muted {
  color: var(--light-muted) !important;
}

.lineheight18 {
  line-height: 18px !important;
}

*::selection {
  background: var(--bs-primary);
  color: #ffffff;
  text-shadow: none;
}

.shadow {
  box-shadow: var(--box-shadow);
}

.form-control,
.form-select {
  border: 1px solid #d1d5db;
  padding: 8px 12px;
  font-size: 14px;
  color: var(--input-color);
  background-color: #fff;
  font-weight: 300;
}

input[type="text"],
input[type="email"],
input[type="number"] {
  height: 40px;
  box-sizing: border-box;
}

.form-control:focus,
.form-check-input:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #ffb499;
}

.primary-filled-button {
  background-color: var(--bs-primary) !important;
  color: #fff;
  border: 1px solid;
  border-color: var(--bs-primary) !important;
  padding: 10px 15px;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.success-filled-button{
  background-color: rgb(38, 195, 38) !important;
  color: #fff;
  border: 1px solid;
  border-color: rgb(38, 195, 38) !important;
  padding: 10px 15px;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.primary-filled-button-ai {
  background-color: #fdac41 !important;
  color: #fff;
  border: 1px solid;
  border-color: #fc960f !important;
  padding: 10px 15px;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.danger-filled-button {
  color: #fff;
  border: 1px solid;
  padding: 10px 15px;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.primary-filled-button:hover,
.primary-filled-button:focus,
.btn:active {
  background-color: var(--bs-hover-primary) !important;
  color: #fff;
  border-color: var(--bs-hover-primary) !important;
}
.primary-filled-button-ai:hover,
.primary-filled-button-ai:focus,
.btn:active {
  background-color: #ffca2c !important;
  color: #fff;
  border-color: #ffc720 !important;
}

.primary-outlined-button {
  background-color: #ffffff;
  color: var(--bs-primary);
  border: 1px solid;
  border-color: var(--bs-primary);
  padding: 10px 15px;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.primary-outlined-button:hover,
.primary-outlined-button:focus,
.btn:active {
  background-color: var(--bs-primary) !important;
  color: #fff;
  border-color: var(--bs-primary) !important;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--border-grey);
  border-radius: 5px;
}

table th {
  background-color: #f3f4f6;
  padding: 8px 12px !important;
  text-align: left !important;
  border-bottom: 1px solid #ddd !important;
  text-transform: uppercase;
  color: var(--muted);
  font-size: 0.75rem;
  font-weight: 500 !important;
}

table th .form-check-input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

table td:last-child,
table th:last-child {
  text-align: right !important;
}

table tr {
  border-bottom: 1px solid #dddddd;
  /* Add border between rows */
}

table tbody td {
  border: none;
  text-align: left !important;
  padding: 15px 12px !important;
  font-size: 0.8rem;
  font-weight: 400;
}

/* Login Wrapper */
.authWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.fxt-form-logo {
  text-align: center;
  padding: 20px;
}

.authWrapper .fxt-content button.btn {
  margin-top: 15px;
  height: 44px;
  font-size: 16px;
}

.authWrapper .fxt-content {
  border: 1px solid #ddd;
  background: #fff;
  padding: 35px;
  border-radius: 12px;
}

/*  admin */

.admin-main-wrapper {
  position: relative;
}

.main-sidebar {
  background-color: #f9f9f9;
  color: #4b5563;
  padding: 0;
  height: 100%;
  border-right: 1px solid #e6e6e6;
  position: fixed;
  left: 0;
  width: 280px;
  top: 0;
}

.sidebar-inner {
  height: calc(100vh - 71px);
  overflow-y: auto;
  position: relative;
}

.main-sidebar .logo {
  padding: 30px 20px 20px;
}

.main-right-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 280px;
}

.top-header {
  background-color: #ffffff;
  color: #ecf0f1;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header button.close_btn,
.top-header button.close_btn:hover,
.top-header button.close_btn:focus,
.top-header button.close_btn:active {
  color: #000;
  background-color: transparent !important;
  border: none;
  display: none;
}

button.close_btn svg {
  width: 28px;
  height: 28px;
}

.top-header-right {
  display: flex;
  align-items: center;
}

.header-actions a,
.header-user a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
}

.header-user svg {
  width: 15px;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 45px;
  margin-right: 15px;
}

.header-actions a svg {
  margin-right: 5px;
  color: var(--bs-primary);
  width: 18px;
}

.header-user {
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  color: #000000;
}

.header-user .header-user-img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  color: #fff;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-user h5 {
  display: inline-block;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-toggle::after {
  border: 0;
}

.dropdown-menu {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  padding: 0;
  overflow: hidden;
  min-width: 200px;
}

.dropdown-menu li a {
  display: flex;
  color: var(--bs-secondary);
  font-size: 14px;
  padding: 7px 15px;
  align-items: center;
  gap: 10px;
}

.dropdown-menu li a:hover {
  background: #e8f0fd;
  color: var(--bs-primary);
}

.main-right-content {
  flex: 1;
  padding: 0 20px;
  overflow-y: auto;
}

.main-sidebar.collapsed {
  width: 80px;
}

.main-sidebar.collapsed ~ .main-right-wrapper {
  padding-left: 80px;
}

.main-sidebar.collapsed li a {
  justify-content: center;
}

.main-sidebar.collapsed li span.menu-title,
.main-sidebar.collapsed .logo_txt {
  display: none;
}

.main-sidebar.collapsed li span.icon-holder {
  margin-right: 0;
}

.sidebar-inner .logo img {
  max-width: 100%;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar .close_btn {
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
}
.sidebar-menu-item {
  padding: 0 10px;
  margin-top: 10px;
}

.sidebar-link {
  text-decoration: none;
  color: #4b5563;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: transparent;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
}

.sidebar-menu-item.active .sidebar-link,
.sidebar-link:hover {
  background: #ffffff;
  color: var(--bs-primary);
  box-shadow: 0px 2px 2px 0px #00000014;
}

.icon-holder {
  margin-right: 10px;
}

.icon-holder i {
  font-size: 20px;
  vertical-align: middle;
}

.sidebar-bottom-menu {
  padding: 0 20px;
  margin-top: 25px;
}

.sidebar-bottom-menu h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.sidebar-bottom-menu ul {
  list-style: disc;
  padding-left: 15px;
}

.sidebar-bottom-menu ul li {
  margin-bottom: 25px;
}

.page-title {
  font-size: 22px;
  margin-bottom: 5px;
}

.dashboard-widget-card {
  gap: 0.8rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.widget-icon-circle {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.lightGrayBox {
  background-color: #f3f4f6;
  color: #000000;
}

.lightPrimaryBox {
  background-color: #fff9e2;
  color: #f05a25;
}

.lightSuccessBox {
  background-color: #f0fdf4;
  color: #22c55e;
}

.reports-wrapper table tbody tr:last-child {
  border-bottom: 0;
}

.tech-image img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.breadcrumb {
  color: #6b7280;
  margin-bottom: 0;
}

.breadcrumb .separator {
  margin: 0 10px;
}

.discover-accordion-section .accordion-item {
  margin-bottom: 25px;
  overflow: hidden;
  padding: 20px 30px 20px 30px;
  border-radius: 20px;
  border: 1px solid #d1d5db !important;
}

.discover-accordion-section .accordion-item:last-child {
  margin-bottom: 0;
}

.discover-accordion-section .accordion-item.opened {
  background: #f4f8ff;
  border-color: #bbd3f9 !important;
}

.discover-accordion-section .accordion-item button.accordion-button {
  padding: 0;
  box-shadow: none;
  background: transparent;
  font-size: 17px;
  color: #0e172a;
  font-weight: 500;
}

.discover-accordion-section .accordion-item button.accordion-button::after {
  display: none;
}

.discover-accordion-section .accordion-item .accordion-body {
  padding: 0;
  padding-top: 5px;
}

.discover-accordion-section .accordion-item p {
  margin: 0;
  font-size: 15px;
  color: #4b5563;
}

.startIconInputField {
  position: relative;
}

.startIconInputField input {
  padding-left: 35px !important;
}

.startIconInputField .iconPosition {
  position: absolute;
  left: 12px;
  top: 8px;
  color: var(--muted);
}

.startIconInputField .iconPosition svg {
  width: 16px;
}

.avatar-card-item {
  border: 1px solid #d1d5db !important;
  padding: 15px 20px !important;
  border-radius: 12px !important;
  background-color: #ffffff !important;
  display: block !important;
  cursor:  pointer !important;
}

.avatar-card-item .icon-box-circle {
  width: 55px;
  height: 55px;
  min-width: 55px;
  background: rgb(240 90 37 / 5%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  color: #f05a25;
  font-size: 32px;
}

.avatar-card-tab-item {
  border: 1px solid #d1d5db !important;
  padding: 15px;
  border-radius: 12px !important;
  background-color: #ffffff !important;
  margin-bottom: 15px;
  text-align: left !important;
  display: block;
}

.avatar-card-tab-item:last-child {
  margin-bottom: 0;
}

.avatar-card-tab-item .icon-box-circle {
  width: 42px;
  height: 42px;
  min-width: 42px;
  background: rgb(240 90 37 / 5%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #f05a25;
  font-size: 32px;
}

.avatar-card-tab-item .icon-box-circle img {
  width: 15px;
}

.avatar-card-tab-item.active {
  border-color: #f05a25 !important;
}

.template-result-output p {
  color: #6b7280;
  font-weight: 300;
}

.sidebar.open ~ .main-right-wrapper .hed_logo {
  display: none !important;
}

.font-muted svg {
  width: 16px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .header-actions {
    gap: 15px;
    margin-right: 0;
  }
  .top-header {
    padding: 15px 15px;
  }
  .page-title {
    font-size: 18px;
    margin-bottom: 0;
  }
  .dashboard-widget-card {
    padding: 8px;
  }
  .breadcrumb {
    font-size: 13px;
  }
  .main-right-content {
    padding: 0 5px;
  }
}

@media (max-width: 991px) {
  .sidebar {
    position: fixed;
    left: -310px;
    z-index: 999;
    width: 310px;
    height: 100vh;
    transition: left 0.3s ease;
  }

  .main-sidebar .logo img.logo_txt {
    width: 156px !important;
  }

  .main-sidebar .logo {
    padding: 30px 12px 20px 20px;
  }

  .sidebar.open {
    left: 0;
  }

  .main-right-wrapper {
    padding-left: 0;
  }

  .sidebar.collapsed li.nav-item span.link-text {
    display: none;
  }

  .sidebar .sidebar-header .close_btn {
    display: block;
  }

  .header-user {
    margin-left: 5px;
  }

  .header-actions {
    gap: 25px;
  }
  .main-right-content {
    padding: 0 5px;
  }
  .top-header button.close_btn,
  .top-header button.close_btn:hover,
  .top-header button.close_btn:focus,
  .top-header button.close_btn:active {
    display: block;
  }
}

@media (max-width: 767px) {
  .authWrapper .fxt-content {
    padding: 15px;
  }
  .authWrapper .fxt-content button.btn {
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .sidebar.collapsed {
    width: 60px;
  }

  .main-sidebar .logo {
    padding: 20px 12px 20px 20px;
  }

  .page-title {
    font-size: 18px;
  }

  .header-actions,
  .header-user h5 {
    display: none;
  }

  .header-user-img {
    margin-right: 5px;
  }

  .top-header-right {
    margin-left: 12px;
  }
  .discover-accordion-section .accordion-item {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
  }
  .discover-accordion-section .accordion-item button.accordion-button {
    font-size: 15px;
  }
  .discover-accordion-section .accordion-item p {
    font-size: 14px;
  }
  .main-right-content {
    padding: 0;
  }
}


.bp5-button-group{
  display: none;
}

.bp5-toast{
  color: #f05a25;
}

/* Arrow styles */
.faq-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.arrow.down {
  border-top: 10px solid #000; /* Arrow down color */
}

.arrow.up {
  border-bottom: 10px solid #000; /* Arrow up color */
}

.show2{
  opacity: 1 !important;
}

.expanded .faq-content {
  display: block;
}

.expanded .arrow.down {
  display: none;
}

.expanded .arrow.up {
  display: inline-block;
}
.input-group-text{
  background-color: white !important;
  border-left: 0px !important;
}
.input-group-text svg {
  color: grey !important;
  width: 19px !important;
}
.tbs .Mui-selected {
  color: #f05a25 !important;
}
.tbs .MuiTabs-indicator {
  background-color: #f05a25 !important;
}
.css-w8dmq8{
  font-family: sans-serif, "JUST Sans" !important;
}


.pre{
  font-family: var(--font-family-sans) !important;
  font-size: 14px !important;
  width: 100% !important;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  margin-bottom: 2rem;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fxt-form-tc{
  text-align: center;
  margin-top: 10px;
}



.modal.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0s; /* Fade-in effect */
  display: flex !important;
  justify-content: center;
  background: transparent;
}

/* Modal dialog box */
.modal-dialog {
  background-color: #fff;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  margin: 0 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-50px);
  transition: transform 0.3s ease;
}

/* Modal content */
.modal-content {
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

/* Modal header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.close {
  font-size: 1.5rem;
  color: #ff0000;
  border: none;
  background: none;
  cursor: pointer;
}

.close:hover {
  color: #000000;
}

/* Modal body */
.modal-body {
  padding: 20px 0;
}

/* Modal footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.modal-footer .btn {
  padding: 6px 12px;
  background-color: #de0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-footer .btn-secondary:hover {
  background-color: #5a6268;
}
