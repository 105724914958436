.sidebar-menu {
    list-style: none; /* Remove default list styles */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
  }
  
  .dropdowns-menu {
    list-style-type: none; /* No bullets */
    padding: 0; /* No padding */
    margin: 0; /* No margin */
    display: block; /* Make it block so it takes full width */
    background-color: #f9f9f9; /* Set background color */
    position: relative; /* Adjust positioning */
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  .dropdowns-item {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: #4b5563;
    padding-left: 50px;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
  }
  